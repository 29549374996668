<template>
	<b-card-group
		id="about-us"
		class="aboutUsStore">
		<b-card
			class="border-0 rounded-0 bg-footer mb-0">
			<div
				class="row p-2 mx-auto mt-3 container">
				<div class="col">
					<div class="row">
						<div class="col-md-4 col-lg-5">
							<!-- Company Logo start -->
							<div
								class="row"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mb-4 text-center' : 'pb-4'">
								<div class="col">
									<img
										class="img-fluid footer-width"
										:class="['md'].includes(windowWidth) ? 'w-100' : ''"
										:src="`${S3_PATH}/assets/footer.png`">
								</div>
							</div>
							<!-- Company Logo end -->
							<!-- #LOVIT logo start -->
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mb-3' : 'py-2 mt-2'"
								class="row text-white">
								<div class="col">
									<img
										:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
										class="img-fluid"
										:src="`${S3_PATH}/assets/lovit.png`">
								</div>
							</div>
							<!-- #LOVIT logo end -->
							<!-- Social media start -->
							<div
								v-if="!isAgencySite"
								:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mb-3' : 'py-2'"
								class="row text-white">
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? 'ml-auto' : ['md'].includes(windowWidth) ? 'pr-2' : ''"
									class="col-auto col-sm-auto">
									<a
										:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
										class="text-white font-weight-normal pointer h4"
										href="https://www.facebook.com/VelovitaOfficial/"
										target="_blank">
										<i class="fab fa-facebook-f" />
									</a>
								</div>
								<div class="col-auto col-sm-auto">
									<a
										:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
										class="text-white font-weight-normal pointer h4"
										href="https://vimeo.com/velovitaofficial"
										target="_blank">
										<i class="fab fa-vimeo" />
									</a>
								</div>
								<div class="col-auto col-sm-auto">
									<a
										:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
										class="text-white font-weight-normal pointer h4"
										href="https://www.linkedin.com/company/velovitaofficial/"
										target="_blank">
										<i class="fab fa-linkedin-in" />
									</a>
								</div>
								<div class="col-auto col-sm-auto">
									<a
										:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
										class="text-white font-weight-normal pointer h4"
										href="https://www.instagram.com/velovita_official/"
										target="_blank">
										<i class="fab fa-instagram" />
									</a>
								</div>
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? 'mr-auto' : ['md'].includes(windowWidth) ? 'pl-2' : ''"
									class="col-auto col-sm-auto">
									<a
										class="text-white font-weight-normal pointer h4"
										href="https://t.me/velovitaofficial"
										target="_blank">
										<i class="fab fa-telegram" />
									</a>
								</div>
							</div>
							<!-- Social media end -->
							<!-- Accepted payment methods start (desktop only) -->
							<div
								:class="{ 'd-none' : ['sm', 'xs'].includes(windowWidth)}"
								class="row mb-4 py-4 mt-2">
								<div class="col-12">
									<div class="row">
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto pl-2'">
											<p
												:class="{
													'h2' : ['md'].includes(windowWidth),
													'h1' : !['md'].includes(windowWidth),
													'white-transparency' : !isAgencySite,
													'text-white' : isAgencySite
												}"
												class="mb-0 text-center">
												<i class="fab fa-cc-amex" />
											</p>
										</div>
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
											<p
												:class="'md' === windowWidth ? 'h2' : 'h1'"
												class="white-transparency mb-0 text-center">
												<i class="fab fa-cc-visa" />
											</p>
										</div>
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
											<p
												:class="'md' === windowWidth ? 'h2' : 'h1'"
												class="white-transparency mb-0 text-center">
												<i class="fab fa-cc-mastercard" />
											</p>
										</div>
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
											<p
												:class="'md' === windowWidth ? 'h2' : 'h1'"
												class="white-transparency mb-0">
												<i class="fab fa-cc-discover" />
											</p>
										</div>
										<div
											:class="'md' === windowWidth ? 'col-2' : 'col-auto pr-2'"
											class="pr-2">
											<p
												:class="'md' === windowWidth ? 'h2' : 'h1'"
												class="white-transparency mb-0">
												<i class="fab fa-cc-diners-club" />
											</p>
										</div>
									</div>
								</div>
							</div>
							<!-- Accepted payment methods end (desktop only) -->
						</div>
						<div class="col-md-8 col-lg-7 d-flex flex-column">
							<div class="row">
								<!-- Site links start -->
								<div class="col-md-6">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
										class="text-white">
										<p class="py-2 px-0 mb-0 headline-small">
											{{ translate('site') }}
										</p>
										<hr
											class="border-white ml-0 mt-0 mb-4"
											style="width: 50px; border-width: 1px">
									</div>
									<div class="row">
										<div class="col-md-6">
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-3">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="website">
													{{ translate('home') }}
												</a>
											</div>
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-3">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="`${website}/#products`">
													{{ translate('products') }}
												</a>
											</div>
											<div
												v-if="(isDistributor || isOpportunitySite)"
												:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
												class="pt-3">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="memberRewards">
													{{ translate('member_rewards') }}
												</a>
											</div>
										</div>
										<div class="col-md-6">
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-3">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="`${website}/about`">
													{{ translate('about') }}
												</a>
											</div>
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-3">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="`${website}/community`">
													{{ translate('community') }}
												</a>
											</div>
											<div
												v-if="isAdminOrCorporate || isDistributor"
												:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'd-none'"
												class="pt-3">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="policyAndProcedures">
													{{ translate('policies_and_procedures') }}
												</a>
											</div>
											<div
												v-if="isAdminOrCorporate || isDistributor"
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-3">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="policyAndProcedures">
													{{ translate('policies_and_procedures_alt') }}
												</a>
											</div>
										</div>
									</div>
								</div>
								<!-- Site links end -->
								<!-- Support info start (desktop only) -->
								<div class="col-md-6 col-lg ml-auto">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
										class="text-white">
										<p class="py-2 px-0 mb-0 headline-small">
											{{ translate('support') }}
										</p>
										<hr
											class="border-white ml-0 mt-0 mb-4"
											style="width: 50px; border-width: 1px">
									</div>
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mt-2' : ''"
										class="text-transparency pt-2">
										<a
											class="text-transparency"
											style="text-decoration: none;"
											:href="`mailto:${csEmail}`">
											<p>
												<i class="fas fa-envelope mr-1 text-white" /> {{ csEmail }}
											</p>
										</a>
										<p class="text-white mt-4">
											<i class="fas fa-headphones-alt mr-1" /> {{ translate('customer_support') }}
										</p>
										<p
											:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''">
											{{ translate('customer_schedule_1', {timezone:timezone}) }}
										</p>
										<p
											:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'mb-0'">
											{{ translate('customer_schedule_2', {timezone:timezone}) }}
										</p>
									</div>
								</div>
								<!-- Support info end (desktop only) -->
							</div>
						</div>
					</div>
					<!-- Accepted payment methods start (mobile only) -->
					<div
						:class="{ 'd-none' : ['md', 'lg', 'xl'].includes(windowWidth)}"
						class="row mb-4">
						<div class="col">
							<div class="row justify-content-center">
								<div class="col-2">
									<p class="white-transparency h1 mb-0 text-center">
										<i class="fab fa-cc-amex fa-sm" />
									</p>
								</div>
								<div class="col-2">
									<p class="white-transparency h1 mb-0 text-center">
										<i class="fab fa-cc-visa fa-sm" />
									</p>
								</div>
								<div class="col-2">
									<p class="white-transparency h1 mb-0 text-center">
										<i class="fab fa-cc-mastercard fa-sm" />
									</p>
								</div>
								<div class="col-2">
									<p class="white-transparency h1 mb-0">
										<i class="fab fa-cc-discover fa-sm" />
									</p>
								</div>
								<div class="col-2">
									<p class="white-transparency h1 mb-0">
										<i class="fab fa-cc-diners-club fa-sm" />
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- Accepted payment methods end (mobile only) -->
					<hr
						class="border-transparency"
						style="border-width: 1px">
					<!-- Copyright and disclaimer start -->
					<div
						:class="{ 'text-center' : ['xs', 'sm'].includes(windowWidth)}"
						class="row font-weight-light">
						<div class="col-12 col-md-4">
							<p class="small text-transparency">
								© {{ currentYear }} {{ companyName }} {{ translate('all_right_reserved') }}
							</p>
							<p
								v-if="ENTITY_MESSAGES_COUNTRIES.includes(country)"
								class="small text-transparency">
								<span class="white-transparency-title">{{ translate('payment_entity_title') }}</span>
								<br>{{ translate('payment_entity_text') }}
							</p>
						</div>
						<div class="col-12 col-md-8 captions disclaimer">
							{{ translate('disclaimer') }}
						</div>
					</div>
					<!-- Copyright and disclaimer end -->
					<!-- <div class="row white-transparency font-weight-light text-center mt-4">
						<div class="col-12 captions">
							{{ translate('covid_disclaimer') }}
						</div>
					</div> -->
				</div>
			</div>
		</b-card>
	</b-card-group>
</template>
<script>
import AgencySite from '@/mixins/AgencySite';
import WindowSizes from '@/mixins/WindowSizes';
import { ENTITY_MESSAGES_COUNTRIES } from '@/settings/Country';
import {
	Store, Products, Grids, Purchase,
} from '@/translations';
import {
	CUSTOMER_SERVICE_EMAIL,
	TERMS_AND_CONDITIONS_REDIRECTION,
	TIMEZONE_CUSTOMER_SERVICE,
	MEMBER_REWARDS_REDIRECTION,
} from '@/settings/General';
import { S3_PATH } from '@/settings/Images';
import {
	admin as adminRoles, distributor,
} from '@/settings/Roles';

export default {
	name: 'StoreFooter',
	messages: [Store, Products, Grids, Purchase],
	mixins: [AgencySite, WindowSizes],
	data() {
		return {
			// Company info
			currentYear: new Date().getFullYear(),
			companyName: process.env.VUE_APP_COMPANY_NAME,
			website: process.env.VUE_APP_WEBSITE,

			// Customer service
			csEmail: CUSTOMER_SERVICE_EMAIL,
			timezone: TIMEZONE_CUSTOMER_SERVICE,

			// Disclaimer
			ENTITY_MESSAGES_COUNTRIES,

			// Images
			S3_PATH,

			// Terms
			policyAndProcedures: TERMS_AND_CONDITIONS_REDIRECTION,

			// Member Rewards
			memberRewards: MEMBER_REWARDS_REDIRECTION,
		};
	},
	computed: {
		isAdminOrCorporate() {
			return adminRoles.includes(this.$user.details().type);
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		isOpportunitySite() {
			return ['Opportunity1LandingPage', 'Opportunity2LandingPage'].includes(this.$route.name);
		},
	},
};
</script>
<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	p {
		font-family: 'Montserrat', sans-serif !important;
	}
	.aboutUsStore {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	.white-transparency-title {
		color: #f1f1f1;
	}
	.white-transparency {
		color: #6c6c6c7a;
	}
	.text-transparency {
		color: #ffffffad;
	}
	.disclaimer{
		color:#828282;
	}
	.border-transparency {
		border-color: #6c6c6cbf;
	}
	.bg-footer {
		background-color:#2b2b2b;
	}
	.footer-width {
		max-width: 265px;
	}
</style>
